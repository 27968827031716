import React from "react";

import { QueryApiState } from "../../app/constants/QueryApiState";
import { tracking } from "../../app/services/tracking";
import { Button } from "../shared/Button";
import { Dialog } from "../shared/Dialog";
import { LoadingContainer } from "../shared/LoadingContainer";
import { Markdown } from "../shared/Markdown";

type AppendicesDialogProps = Omit<
  React.ComponentProps<typeof Dialog>,
  "children"
> & {
  apiState: QueryApiState<string>;
  dataLayer: {
    page: { category_page: string; nom_page: string };
    trigger: { eventAction: string; eventCategory: string; eventLabel: string };
  };
  messages: { error: { [status: string]: string; unknown: string } };
  title: string;
  trigger: string;
};

export const AppendicesDialog = (props: AppendicesDialogProps) => {
  const {
    apiState,
    dataLayer,
    messages,
    title,
    trigger,
    onOpenChange,
    ...restProps
  } = props;
  return (
    <Dialog
      {...restProps}
      onOpenChange={(open) => {
        onOpenChange?.(open);

        if (open) {
          tracking.trackPage(dataLayer.page);
        }
      }}
    >
      <Dialog.Trigger>
        <Button onClick={() => tracking.trackEvent(dataLayer.trigger)}>
          {trigger}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="full">
        <Dialog.Header>
          <Dialog.Title>{title}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <LoadingContainer apiState={apiState} messages={messages}>
            {(text) => <Markdown>{text}</Markdown>}
          </LoadingContainer>
        </Dialog.Body>
        <Dialog.Close />
      </Dialog.Content>
    </Dialog>
  );
};
