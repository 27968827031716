import { Params, generatePath } from "react-router-dom";

export function initialize<TRouteKey extends string>(
  routes: Record<TRouteKey, string>,
) {
  const getPath = (routeKey: TRouteKey) => routes[routeKey].split("?")[0];

  const getRelativePath = (from: TRouteKey, to: TRouteKey) =>
    getPath(to).replace(getPath(from).replace("/*", ""), "");

  const getTo = (routeKey: TRouteKey, params?: Params<string>) =>
    generatePath(routes[routeKey].replace("/*", ""), params);

  return { getPath, getRelativePath, getTo };
}
