import { yupResolver } from "@hookform/resolvers/yup";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { tracking } from "../../../app/services/tracking";
import { FieldController } from "../../shared/FieldController";
import { PasswordField } from "../../shared/PasswordField";
import { AuthenticationForm } from "../shared/AuthenticationForm";
import { useCompleteNewPassword } from "./useCompleteNewPassword";

type CompleteNewPasswordFormProps = { user: CognitoUser };

export const CompleteNewPasswordForm = (
  props: CompleteNewPasswordFormProps,
) => {
  const { user } = props;

  const { t } = useTranslation();
  const { fields, messages, submit, title } = t(
    "authentication.completeNewPassword.CompleteNewPasswordForm",
  ) as unknown as {
    fields: {
      confirmPassword: {
        label: string;
        marker: string;
        messages: { mismatch: string; required: string };
        placeholder: string;
      };
      password: {
        label: string;
        marker: string;
        messages: { invalid: string; required: string };
        placeholder: string;
      };
    };
    messages: { error: { unknown: string }; success: string };
    submit: string;
    title: string;
  };

  const [completeNewPassword, completeNewPasswordApiState] =
    useCompleteNewPassword();
  const { control, handleSubmit } = useForm({
    defaultValues: { confirmPassword: "", password: "" },
    resolver: yupResolver(
      yup.object().shape({
        confirmPassword: yup
          .string()
          .required(fields.confirmPassword.messages.required)
          .oneOf(
            [yup.ref("password")],
            fields.confirmPassword.messages.mismatch,
          ),
        password: yup
          .string()
          .required(fields.password.messages.required)
          .matches(
            /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
            fields.password.messages.invalid,
          ),
      }),
    ),
  });

  return (
    <AuthenticationForm
      apiState={completeNewPasswordApiState}
      messages={messages}
      submit={submit}
      title={title}
      onSubmit={handleSubmit((values) => {
        tracking.trackEvent({
          eventAction: "modification_mdp",
          eventCategory: "home_deconnectee",
          eventLabel: "finaliser_votre_inscription",
        });
        return completeNewPassword(user, values.password);
      })}
    >
      <FieldController
        control={control}
        name="password"
        render={(controllerProps) => (
          <PasswordField
            {...controllerProps.field}
            isRequired
            label={fields.password.label}
            markerLabel={fields.password.marker}
          />
        )}
      />
      <FieldController
        control={control}
        name="confirmPassword"
        render={(controllerProps) => (
          <PasswordField
            {...controllerProps.field}
            isRequired
            label={fields.confirmPassword.label}
            markerLabel={fields.confirmPassword.marker}
          />
        )}
      />
    </AuthenticationForm>
  );
};
