import "./AuthenticationAd.scss";

const classNamePrefix = "AuthenticationAd";

export const AuthenticationAd = () => (
  <div className={classNamePrefix}>
    <div>
      <img alt="" src="/assets/ads/ad.png" />
    </div>
  </div>
);
