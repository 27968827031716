import { AppendicesNav } from "../../appendices/AppendicesNav";

import "./AuthenticationFooter.scss";

const classNamePrefix = "AuthenticationFooter";

export const AuthenticationFooter = () => (
  <footer className={classNamePrefix}>
    <AppendicesNav />
  </footer>
);
