import { useTranslation } from "react-i18next";

import "./AuthenticationWebsitesNav.scss";

const classNamePrefix = "AuthenticationWebsitesNav";

export const AuthenticationWebsitesNav = () => {
  const { t } = useTranslation();
  const { label, links } = t(
    "authentication.core.AuthenticationWebsitesNav",
  ) as unknown as {
    label: string;
    links: {
      current?: true;
      href: string;
      key: "largePrivateInvestor" | "particular";
      label: string;
      title: string;
    }[];
  };

  return (
    <nav aria-label={label} className={classNamePrefix}>
      <ul>
        {links.map(({ key, ...link }) => (
          <li key={key}>
            <a aria-current={link.current} href={link.href} title={link.title}>
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
