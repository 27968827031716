import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { tracking } from "../../../app/services/tracking";
import { FieldController } from "../../shared/FieldController";
import { PasswordField } from "../../shared/PasswordField";
import { TextField } from "../../shared/TextField";
import { CompleteNewPasswordForm } from "../completeNewPassword/CompleteNewPasswordForm";
import { AuthenticationForm } from "../shared/AuthenticationForm";
import { useSignIn } from "./useSignIn";

export const SignInForm = () => {
  const { t } = useTranslation();
  const { fields, messages, submit, title } = t(
    "authentication.signIn.SignInForm",
  ) as unknown as {
    fields: {
      email: { label: string; messages: { invalid: string; required: string } };
      password: {
        label: string;
        marker: string;
        messages: { required: string };
      };
    };
    messages: { error: { unknown: string }; success: string };
    submit: string;
    title: string;
  };

  const [signIn, signInApiState] = useSignIn();
  const { control, handleSubmit } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .required(fields.email.messages.required)
          .email(fields.email.messages.invalid),
        password: yup.string().required(fields.password.messages.required),
      }),
    ),
  });

  if (signInApiState.error?.code === "NEW_PASSWORD_REQUIRED") {
    return <CompleteNewPasswordForm user={signInApiState.error.user} />;
  }

  return (
    <AuthenticationForm
      apiState={signInApiState}
      messages={messages}
      submit={submit}
      title={title}
      onSubmit={handleSubmit((values) => {
        tracking.trackEvent({
          eventAction: "connexion_compte",
          eventCategory: "home_deconnectee",
          eventLabel: "se_connecter",
        });
        return signIn(values.email, values.password);
      })}
    >
      <FieldController
        control={control}
        name="email"
        render={(controllerProps) => (
          <TextField
            {...controllerProps.field}
            autoComplete="username"
            isRequired
            label={fields.email.label}
            type="email"
          />
        )}
      />
      <FieldController
        control={control}
        name="password"
        render={(controllerProps) => (
          <PasswordField
            {...controllerProps.field}
            autoComplete="current-password"
            isRequired
            label={fields.password.label}
            markerLabel={fields.password.marker}
          />
        )}
      />
    </AuthenticationForm>
  );
};
