import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import { RootErrorBoundary } from "./RootErrorBoundary";
import { i18next } from "./app/config/i18next";
import store from "./app/store";

export const Root = () => (
  <React.StrictMode>
    <RootErrorBoundary>
      <Provider store={store}>
        <BrowserRouter
          future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
          }}
        >
          <I18nextProvider i18n={i18next}>
            <React.Suspense fallback={null}>
              <App />
            </React.Suspense>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    </RootErrorBoundary>
  </React.StrictMode>
);
