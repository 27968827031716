export type MutationApiState<TData> = {
  data?: TData;
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isUninitialized: boolean;
};

export function getMutationApiStates<TData>() {
  const failed = (error: MutationApiState<TData>["error"]) => ({
    error,
    isError: true,
    isLoading: false,
    isSuccess: false,
    isUninitialized: false,
  });

  const idle = () => ({
    isError: false,
    isLoading: false,
    isSuccess: false,
    isUninitialized: true,
  });

  const loading = () => ({
    isError: false,
    isLoading: true,
    isSuccess: false,
    isUninitialized: false,
  });

  const succeeded = (data: MutationApiState<TData>["data"]) => ({
    data,
    isError: false,
    isLoading: false,
    isSuccess: true,
    isUninitialized: false,
  });

  return { failed, idle, loading, succeeded };
}
