import React from "react";

import { QueryApiState } from "../../app/constants/QueryApiState";
import { Button } from "./Button";
import { Spinner } from "./Spinner";

const classNamePrefix = "LoadingContainer";

type LoadingContainerProps<TData> = {
  apiState: QueryApiState<TData>;
  children: (data: TData) => React.ReactNode;
  messages: { error: { [status: string]: string; unknown: string } };
  refetchLabel?: string;
};

export const LoadingContainer = React.forwardRef(
  <TData extends unknown>(
    props: LoadingContainerProps<TData>,
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      apiState,
      children,
      messages,
      refetchLabel = "Rééssayer",
      ...restProps
    } = props;

    if (apiState.isFetching || apiState.isUninitialized) {
      return (
        <div ref={ref} {...restProps} className={classNamePrefix}>
          <Spinner className={`${classNamePrefix}-spinner`} />
        </div>
      );
    }

    if (apiState.isError) {
      return (
        <div ref={ref} {...restProps} className={classNamePrefix}>
          {messages.error[apiState.error.status] || messages.error.unknown}
          <Button type="button" variant="action" onClick={apiState.refetch}>
            {refetchLabel}
          </Button>
        </div>
      );
    }

    return <>{children(apiState.data as TData)}</>;
  },
) as <TData extends unknown>(
  props: LoadingContainerProps<TData> & {
    ref?: React.ForwardedRef<HTMLDivElement>;
  },
) => React.ReactNode;
