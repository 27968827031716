import { useGetHealthCheckQuery } from "../../../app/api/public";
import { Alert } from "../../shared/Alert";

export const AuthenticationInformationNotice = () => {
  const getHealthCheckApiState = useGetHealthCheckQuery();
  const { informationNotice } = getHealthCheckApiState.data || {
    informationNotice: { message: "", status: false },
  };

  if (!informationNotice.status) {
    return null;
  }

  return <Alert status="info">{informationNotice.message}</Alert>;
};
