import { useTranslation } from "react-i18next";

import { Button } from "../shared/Button";
import { LegalNoticeDialog } from "./LegalNoticeDialog";
import { TermsOfServiceDialog } from "./TermsOfServiceDialog";

import "./AppendicesNav.scss";

declare const window: Window & { Didomi?: any };

const classNamePrefix = "AppendicesNav";

export const AppendicesNav = () => {
  const { t } = useTranslation();
  const label = t("appendices.AppendicesNav.label");

  return (
    <nav aria-label={label} className={classNamePrefix}>
      <ul>
        <CookiesManagementLink />
        <li>
          <TermsOfServiceDialog />
        </li>
        <li>
          <LegalNoticeDialog />
        </li>
      </ul>
    </nav>
  );
};

const CookiesManagementLink = () => {
  const { t } = useTranslation();
  const label = t("appendices.AppendicesNav.links.cookiesManagement");

  return (
    <li>
      <Button type="button" onClick={() => window.Didomi?.preferences.show()}>
        {label}
      </Button>
    </li>
  );
};
