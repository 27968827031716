import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { router } from "../../../app/services/router";
import { tracking } from "../../../app/services/tracking";
import { Button } from "../../shared/Button";

export const AuthenticationForgotPasswordLink = () => {
  const { t } = useTranslation();
  const { label } = t(
    "authentication.shared.AuthenticationForgotPasswordLink",
  ) as unknown as { label: string };

  return (
    <Button
      asChild
      icon="next"
      variant="text"
      onClick={() =>
        tracking.trackEvent({
          eventAction: "connexion_compte",
          eventCategory: "home_deconnectee",
          eventLabel: "mdp_oublié",
        })
      }
    >
      <Link to={router.getTo("forgotPassword")}>{label}</Link>
    </Button>
  );
};
