import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import React from "react";

import { AuthenticationApp } from "./AuthenticationApp";
import { useGetCurrentUserInfoQuery } from "./app/api/private/currentUser";
import { useGetAppConfigQuery, useGetMaintenanceQuery } from "./app/api/public";
import {
  QueryApiState,
  getQueryApiStates,
} from "./app/constants/QueryApiState";
import { splashScreen } from "./app/services/splashScreen";
import { useAppSelector } from "./app/store";
import { selectCurrentUser } from "./app/store/auth";
import { ScrollRestorer } from "./features/core/ScrollRestorer";
import { Tracking } from "./features/core/Tracking";
import { InitErrorPage } from "./pages/InitErrorPage";
import { MaintenancePage } from "./pages/MaintenancePage";

const MainApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./MainApp").then((module) => ({
    default: module.MainApp,
  })),
);

Hub.listen("auth", (data) => {
  if (data.payload.event === "signOut") {
    window.history.pushState({}, "", "/");
    window.location.reload();
  }
});

export const App = () => {
  const getAppConfigApiState = useGetAppConfigQuery();

  const { currentData: maintenance } = useGetMaintenanceQuery(undefined, {
    pollingInterval: 15 * 60 * 1000,
    skip: !getAppConfigApiState.isSuccess,
  });
  if (maintenance?.enabled) {
    splashScreen.remove();
    return <MaintenancePage />;
  }

  if (getAppConfigApiState.isError) {
    splashScreen.remove();
    return <InitErrorPage />;
  }

  if (getAppConfigApiState.isSuccess) {
    return <Main />;
  }

  return null;
};

const Main = () => {
  const synchronizeCurrentUserApiState = useSynchronizeCurrentUser();

  if (
    synchronizeCurrentUserApiState.isLoading ||
    synchronizeCurrentUserApiState.isUninitialized
  ) {
    return null;
  }

  splashScreen.remove();
  return (
    <>
      <Routes />
      <ScrollRestorer />
      <Tracking />
    </>
  );
};

const Routes = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  if (currentUser && currentUser.hasAcceptedTermsOfService) {
    return (
      <React.Suspense fallback={null}>
        <MainApp />
      </React.Suspense>
    );
  }

  return <AuthenticationApp />;
};

function useSynchronizeCurrentUser() {
  const synchronizeCurrentUserApiStates = React.useMemo(
    () => getQueryApiStates<unknown>(),
    [],
  );
  const [synchronizeCurrentUserApiState, setSynchronizeCurrentUserApiState] =
    React.useState(synchronizeCurrentUserApiStates.idle());

  const getCurrentUserInfoApiState = useGetCurrentUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !synchronizeCurrentUserApiState.isSuccess,
  });

  React.useEffect(() => {
    const synchronizeCurrentUser = async () => {
      setSynchronizeCurrentUserApiState(
        synchronizeCurrentUserApiStates.loading(),
      );

      try {
        const user = await Auth.currentAuthenticatedUser();
        setSynchronizeCurrentUserApiState(
          synchronizeCurrentUserApiStates.succeeded(user),
        );
      } catch (error) {
        setSynchronizeCurrentUserApiState(
          synchronizeCurrentUserApiStates.failed(error),
        );
      }
    };
    synchronizeCurrentUser();
  }, [synchronizeCurrentUserApiStates]);

  return (
    getCurrentUserInfoApiState.isUninitialized
      ? synchronizeCurrentUserApiState
      : getCurrentUserInfoApiState
  ) as QueryApiState<unknown>;
}
