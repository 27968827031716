import { useTranslation } from "react-i18next";

import { AuthenticationForgotPasswordLink } from "../features/authentication/shared/AuthenticationForgotPasswordLink";
import { SignInForm } from "../features/authentication/signIn/SignInForm";
import { AuthenticationPage } from "./shared/AuthenticationPage";

export const SignInPage = () => {
  const { t } = useTranslation();
  const { meta, title } = t("pages.SignInPage") as unknown as {
    meta: { title: string };
    title: string;
  };

  return (
    <AuthenticationPage
      dataLayer={{
        category_page: "home_deconnectee",
        nom_page: "Home déconnectée - Login",
      }}
      meta={meta}
    >
      <AuthenticationPage.Title>{title}</AuthenticationPage.Title>
      <SignInForm />
      <AuthenticationForgotPasswordLink />
    </AuthenticationPage>
  );
};
