import React from "react";

import { MutationApiState } from "../../../app/constants/MutationApiState";
import { Alert } from "../../shared/Alert";
import { Button } from "../../shared/Button";

import "./AuthenticationForm.scss";

const classNamePrefix = "AuthenticationForm";

type AuthenticationFormProps<TData> = {
  apiState: MutationApiState<TData>;
  children: React.ReactNode;
  messages: {
    error: { [code: string]: string; unknown: string };
    success: string;
  };
  submit: string;
  title: string;
  onSubmit: () => void;
};

export const AuthenticationForm = <TData extends unknown>(
  props: AuthenticationFormProps<TData>,
) => {
  const { apiState, children, messages, submit, title, onSubmit } = props;
  return (
    <div className={classNamePrefix}>
      <h2 id="AuthenticationForm-title">{title}</h2>
      <form
        aria-labelledby="AuthenticationForm-title"
        noValidate
        onSubmit={onSubmit}
      >
        {apiState.isError && (
          <Alert status="error">
            {messages.error[apiState.error.code] || messages.error.unknown}
          </Alert>
        )}
        {apiState.isSuccess && (
          <Alert status="success">{messages.success}</Alert>
        )}
        {children}
        <Button
          isSubmitting={apiState.isLoading}
          type="submit"
          variant="action"
        >
          {submit}
        </Button>
      </form>
    </div>
  );
};
