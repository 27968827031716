import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { router } from "../../../app/services/router";

import "./AuthenticationNavBar.scss";

const classNamePrefix = "AuthenticationNavBar";

export const AuthenticationNavBar = () => {
  const { t } = useTranslation();
  const { label } = t(
    "authentication.core.AuthenticationNavBar",
  ) as unknown as { label: string };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}-logo`}>
        <Link aria-label={label} to={router.getTo("home")}>
          <img alt="" height="52" src="/app.svg" width="180" />
        </Link>
      </div>
    </div>
  );
};
