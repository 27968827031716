import { AuthenticationAppRoutes } from "./AuthenticationAppRoutes";
import { AcceptTermsOfServiceAlertDialog } from "./features/authentication/AcceptTermsOfServiceAlertDialog";
import { AuthenticationAd } from "./features/authentication/core/AuthenticationAd";
import { AuthenticationFooter } from "./features/authentication/core/AuthenticationFooter";
import { AuthenticationHeader } from "./features/authentication/core/AuthenticationHeader";
import { AuthenticationInformationNotice } from "./features/authentication/core/AuthenticationInformationNotice";

import "./AuthenticationApp.scss";

const classNamePrefix = "AuthenticationApp";

export const AuthenticationApp = () => (
  <>
    <div className={classNamePrefix}>
      <AuthenticationHeader />
      <main className={`${classNamePrefix}-main`}>
        <div className={`${classNamePrefix}-routes`}>
          <AuthenticationInformationNotice />
          <AuthenticationAppRoutes />
        </div>
        <div className={`${classNamePrefix}-ad`}>
          <AuthenticationAd />
        </div>
      </main>
      <AuthenticationFooter />
    </div>
    <AcceptTermsOfServiceAlertDialog />
  </>
);
