import { AuthenticationNavBar } from "./AuthenticationNavBar";
import { AuthenticationWebsitesNav } from "./AuthenticationWebsitesNav";

import "./AuthenticationHeader.scss";

const classNamePrefix = "AuthenticationHeader";

export const AuthenticationHeader = () => (
  <header className={classNamePrefix}>
    <AuthenticationWebsitesNav />
    <AuthenticationNavBar />
  </header>
);
