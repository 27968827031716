const routes = {
  currentUser: "/mon-profil",
  documents: "/documents",
  forgotPassword: "/mot-de-passe-oublie",
  forgotPasswordSubmit: "/reinitialisation-du-mot-de-passe-oublie",
  home: "/",
  interlocutors: "/interlocuteurs",
  patrimony: "/patrimoine/*",
  patrimonyLots: "/patrimoine/lots",
  patrimonyMandate: "/patrimoine/mandats/:mandateId/*",
  patrimonyMandateBuilding:
    "/patrimoine/mandats/:mandateId/immeubles/:buildingId/*",
  patrimonyMandateBuildingLot:
    "/patrimoine/mandats/:mandateId/immeubles/:buildingId/lots/:lotId/*",
  patrimonyMandateBuildingLots:
    "/patrimoine/mandats/:mandateId/immeubles/:buildingId/lots/*",
  patrimonyMandateBuildings: "/patrimoine/mandats/:mandateId/immeubles/*",
  patrimonyMandates: "/patrimoine/mandats/*",
  reportings: "/reportings/*",
  reportingsDashboard: "/reportings/tableau-de-bord",
  reportingsReportingDocuments: "/reportings/reportings-statiques",
};

export type RouteKey = keyof typeof routes;

export default routes;
