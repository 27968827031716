import React from "react";
import { useTranslation } from "react-i18next";

import { useAcceptTermsOfServiceMutation } from "../../app/api/private/currentUser";
import { useLazyGetTermsOfServiceQuery } from "../../app/api/public";
import { tracking } from "../../app/services/tracking";
import { useAppSelector } from "../../app/store";
import { selectCurrentUser } from "../../app/store/auth";
import { Button } from "../shared/Button";
import { ButtonContainer } from "../shared/ButtonContainer";
import { Dialog } from "../shared/Dialog";
import { LoadingContainer } from "../shared/LoadingContainer";
import { Markdown } from "../shared/Markdown";

export const AcceptTermsOfServiceAlertDialog = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const hasToAcceptTermsOfService = !!(
    currentUser && !currentUser.hasAcceptedTermsOfService
  );

  const [getText, getTextApiState] = useLazyGetTermsOfServiceQuery();

  const { t } = useTranslation();
  const { action, messages, title } = t(
    "authentication.AcceptTermsOfServiceAlertDialog",
  ) as unknown as {
    action: string;
    messages: { error: { unknown: string } };
    title: string;
  };

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [acceptTermsOfService, acceptTermsOfServiceApiState] =
    useAcceptTermsOfServiceMutation();

  React.useEffect(() => {
    if (hasToAcceptTermsOfService) {
      getText(undefined, true);
      tracking.trackPage({
        category_page: "home_deconnectee",
        nom_page: "Home déconnectée - Acceptation CGU",
      });
    }
  }, [getText, hasToAcceptTermsOfService]);

  return (
    <Dialog open={hasToAcceptTermsOfService}>
      <Dialog.Content size="full">
        <Dialog.Header>
          <Dialog.Title>{title}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body
          onScroll={(event: any) => {
            if (
              Math.ceil(
                event.target.scrollTop + event.target.clientHeight + 10,
              ) >= event.target.scrollHeight
            ) {
              setIsDisabled(false);
            }
          }}
        >
          <LoadingContainer apiState={getTextApiState} messages={messages}>
            {(text) => <Markdown>{text}</Markdown>}
          </LoadingContainer>
        </Dialog.Body>
        <Dialog.Footer>
          <ButtonContainer
            action={
              <Button
                disabled={isDisabled}
                isSubmitting={acceptTermsOfServiceApiState.isLoading}
                type="button"
                variant="action"
                onClick={() => {
                  acceptTermsOfService();
                  tracking.trackEvent({
                    eventAction: "acceptation_cgu",
                    eventCategory: "home_deconnectee",
                    eventLabel: "valider",
                  });
                }}
              >
                {action}
              </Button>
            }
            label="Faites défiler le texte afin de pouvoir cliquer sur le bouton."
            variant="accept"
          />
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
};
