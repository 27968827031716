import React from "react";

import { tracking } from "../../app/services/tracking";
import { Head } from "../../features/shared/Head";

const classNamePrefix = "AuthenticationPage";

type AuthenticationPageRootProps = {
  children: React.ReactNode;
  dataLayer: { category_page: string; nom_page: string };
  meta: { title: string };
};

const AuthenticationPageRoot = (props: AuthenticationPageRootProps) => {
  const { children, dataLayer, meta } = props;

  React.useEffect(() => tracking.trackPage(dataLayer), [dataLayer]);

  return (
    <>
      <Head {...meta} />
      <div className={classNamePrefix}>{children}</div>
    </>
  );
};

type AuthenticationPageTitleProps = { children: React.ReactNode };

const AuthenticationPageTitle = (props: AuthenticationPageTitleProps) => {
  const { children } = props;
  return <h1>{children}</h1>;
};

export const AuthenticationPage = Object.assign(AuthenticationPageRoot, {
  Title: AuthenticationPageTitle,
});
