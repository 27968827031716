import * as RadixUiDialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import React from "react";

import { CloseButton } from "./CloseButton";
import { Overlay } from "./Overlay";

const classNamePrefix = "Dialog";

type DialogRootProps = RadixUiDialog.DialogProps;

const DialogRoot = (props: DialogRootProps) => (
  <RadixUiDialog.Root {...props} />
);

type DialogBodyProps = React.HTMLAttributes<HTMLDivElement>;

const DialogBody = React.forwardRef(
  (props: DialogBodyProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-body`)}
      />
    );
  },
);

type DialogCloseProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const DialogClose = React.forwardRef(
  (props: DialogCloseProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      "aria-label": ariaLabel = "Fermer",
      className,
      ...restProps
    } = props;
    return (
      <RadixUiDialog.DialogClose asChild>
        <CloseButton
          ref={ref}
          {...restProps}
          aria-label={ariaLabel}
          className={classNames(className, `${classNamePrefix}-close`)}
        />
      </RadixUiDialog.DialogClose>
    );
  },
);

type DialogContentProps = RadixUiDialog.DialogContentProps & {
  image?: React.ReactNode;
  size?: "alert" | "full" | "lg" | "sm" | "xl";
};

const DialogContent = React.forwardRef(
  (props: DialogContentProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { children, className, image, size, ...restProps } = props;
    return (
      <RadixUiDialog.Portal>
        <RadixUiDialog.Overlay asChild>
          <Overlay />
        </RadixUiDialog.Overlay>
        <RadixUiDialog.Content
          ref={ref}
          {...restProps}
          className={classNames(className, classNamePrefix)}
          data-image={image ? "true" : undefined}
          data-size={size}
        >
          {image && <div aria-hidden>{image}</div>}
          <div>{children}</div>
        </RadixUiDialog.Content>
      </RadixUiDialog.Portal>
    );
  },
);

type DialogFooterProps = React.HTMLAttributes<HTMLDivElement>;

const DialogFooter = React.forwardRef(
  (props: DialogFooterProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-footer`)}
      />
    );
  },
);

type DialogHeaderProps = React.HTMLAttributes<HTMLDivElement>;

const DialogHeader = React.forwardRef(
  (props: DialogHeaderProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, `${classNamePrefix}-header`)}
      />
    );
  },
);

type DialogTitleProps = React.HTMLAttributes<HTMLHeadingElement> & {
  icon?: React.ReactNode;
};

const DialogTitle = React.forwardRef(
  (props: DialogTitleProps, ref?: React.ForwardedRef<HTMLHeadingElement>) => {
    const { children, className, icon, ...restProps } = props;
    return (
      <RadixUiDialog.Title asChild>
        <h1
          ref={ref}
          {...restProps}
          className={classNames(className, `${classNamePrefix}-title`)}
          data-icon={icon ? "true" : undefined}
        >
          {icon}
          {children}
        </h1>
      </RadixUiDialog.Title>
    );
  },
);

type DialogTriggerProps = RadixUiDialog.DialogTriggerProps;

const DialogTrigger = React.forwardRef(
  (props: DialogTriggerProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
    const { asChild = true, ...restProps } = props;
    return <RadixUiDialog.Trigger ref={ref} {...restProps} asChild={asChild} />;
  },
);

export const Dialog = Object.assign(DialogRoot, {
  Body: DialogBody,
  Close: DialogClose,
  Content: DialogContent,
  Footer: DialogFooter,
  Header: DialogHeader,
  Title: DialogTitle,
  Trigger: DialogTrigger,
});
