export enum EnvType {
  DEV = "DEV",
  PRD = "PRD",
  QUA = "QUA",
}

export const envType =
  {
    "dev.mon-espace-immo-grands-comptes.fr": EnvType.DEV,
    "qua.mon-espace-immo-grands-comptes.fr": EnvType.QUA,
  }[window.location.hostname.replace("www.", "")] ?? EnvType.PRD;
