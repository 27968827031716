import { Auth } from "@aws-amplify/auth";
import React from "react";

import { useGetCurrentUserInfoQuery } from "../../../app/api/private/currentUser";
import {
  MutationApiState,
  getMutationApiStates,
} from "../../../app/constants/MutationApiState";

export function useCompleteNewPassword() {
  const completeNewPasswordApiStates = getMutationApiStates<unknown>();
  const [completeNewPasswordApiState, setCompleteNewPasswordApiState] =
    React.useState(completeNewPasswordApiStates.idle());

  const completeNewPassword = async (
    ...args: Parameters<typeof Auth.completeNewPassword>
  ) => {
    setCompleteNewPasswordApiState(completeNewPasswordApiStates.loading());

    try {
      const user = await Auth.completeNewPassword(...args);
      setCompleteNewPasswordApiState(
        completeNewPasswordApiStates.succeeded(user),
      );
    } catch (error) {
      setCompleteNewPasswordApiState(
        completeNewPasswordApiStates.failed(error),
      );
    }
  };

  const getCurrentUserInfoApiState = useGetCurrentUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !completeNewPasswordApiState.isSuccess,
  });

  return [
    completeNewPassword,
    (getCurrentUserInfoApiState.isUninitialized
      ? completeNewPasswordApiState
      : getCurrentUserInfoApiState) as MutationApiState<unknown>,
  ] as const;
}
