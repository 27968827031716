import { useTranslation } from "react-i18next";

import { useLazyGetTermsOfServiceQuery } from "../../app/api/public";
import { AppendicesDialog } from "./AppendicesDialog";

export const TermsOfServiceDialog = () => {
  const [getText, getTextApiState] = useLazyGetTermsOfServiceQuery();

  const { t } = useTranslation();
  const { messages, title, trigger } = t(
    "appendices.TermsOfServiceDialog",
  ) as unknown as {
    messages: { error: { unknown: string } };
    title: string;
    trigger: string;
  };

  return (
    <AppendicesDialog
      apiState={getTextApiState}
      dataLayer={{
        page: {
          category_page: "cgu",
          nom_page: "CGU",
        },
        trigger: {
          eventAction: "clic_footer",
          eventCategory: "footer",
          eventLabel: "CGU",
        },
      }}
      messages={messages}
      title={title}
      trigger={trigger}
      onOpenChange={() => getText(undefined, true)}
    />
  );
};
