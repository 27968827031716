import { Auth } from "@aws-amplify/auth";
import React from "react";

import { useGetCurrentUserInfoQuery } from "../../../app/api/private/currentUser";
import {
  MutationApiState,
  getMutationApiStates,
} from "../../../app/constants/MutationApiState";

export function useSignIn() {
  const signInApiStates = getMutationApiStates<unknown>();
  const [signInApiState, setSignInApiState] = React.useState(
    signInApiStates.idle(),
  );

  const signIn = async (...args: Parameters<typeof Auth.signIn>) => {
    setSignInApiState(signInApiStates.loading());

    try {
      const user = await Auth.signIn(...args);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setSignInApiState(
          signInApiStates.failed({ code: user.challengeName, user }),
        );
      } else {
        setSignInApiState(signInApiStates.succeeded(user));
      }
    } catch (error) {
      setSignInApiState(signInApiStates.failed(error));
    }
  };

  const getCurrentUserInfoApiState = useGetCurrentUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !signInApiState.isSuccess,
  });

  return [
    signIn,
    (getCurrentUserInfoApiState.isUninitialized
      ? signInApiState
      : getCurrentUserInfoApiState) as MutationApiState<unknown>,
  ] as const;
}
